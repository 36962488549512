import Autolinker from 'autolinker';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import sha1 from 'crypto-js/sha1';
import hexRGB from 'hex-rgb';
import { flow } from 'lodash';

import { digestKey } from '../constants';

/**
 * Compares two dates and returns the
 * number of minutes they are apart.
 *
 * @param {date} dateOne - a Date object
 * @param {date} dateTwo - a Date object
 # @return {number} difference in minutes between the two dates
 */
export function minutesApart(dateOne, dateTwo) {
  return Math.round(Math.abs((dateOne - dateTwo) / 1000 / 60));
}

export function ensureTwoDigitDate(str) {
  if (str.length === 1) {
    return `0${str}`;
  }
  return str;
}

/**
 * Capitalizes camel-cased 'ID' strings.
 *
 * @param {string} str any string
 * @returns 'ID' if the string is 'id', 'Id', 'iD', or 'ID'. Otherwise, it just returns the argument.
 */
export function capitalizeId(str) {
  if (str.toLowerCase() === 'id') {
    return 'ID';
  }

  return str;
}

export function titleize(str) {
  const capitalized = str[0].toUpperCase() + str.slice(1);

  return capitalized
    .split(/(?=[A-Z])/) // Split on capital letters
    .map(capitalizeId)
    .join(' ');
}

/**
 * Returns the past-tense version of a verb.
 *
 * @param {string} str A verb.
 * @returns The past tense version of a verb.
 */
export function getPastTense(str) {
  const lastCharacter = str.substr(-1);

  if (lastCharacter === 'd') {
    return str;
  }

  if (lastCharacter === 'e') {
    return `${str}d`;
  }

  return `${str}ed`;
}

export const getTitlelizedPastTense = flow([titleize, getPastTense]);

export function parameterize(str) {
  return str.toLowerCase().split(/\s+/).join('_');
}

export const capitalizeFirstLetter = (string, lowercaseOtherLetters = true) => {
  if (!string || !string.length) return '';

  const capitalizedFirstLetter = string.charAt(0).toUpperCase();
  const otherLetters = lowercaseOtherLetters
    ? string.slice(1).toLowerCase()
    : string.slice(1);
  const capitalizedString = capitalizedFirstLetter + otherLetters;

  return capitalizedString.replace(/_/g, ' ');
};

export const capitalizeWords = (string) => {
  if (!string) return '';

  string = string.replace(/_|-/g, ' ');

  return string
    .split(' ')
    .map((word) => {
      if (!word.length) return '';

      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

export const toUpperCaseWithUnderscores = (string = '') => {
  return string.toUpperCase().replace(/\s/g, '_');
};

export const toLowerCaseWithSpaces = (string = '') => {
  return string
    .split('_')
    .map((word) => word.toLowerCase())
    .join(' ');
};

/**
 * Formats a Date object into a string with a MM/DD/YYYY format.
 * @example
 * const dateString = toDateString(new Date(2020, 0, 1));
 * // "01/01/2020"
 *
 * @param {Date} date A Date object to format.
 * @return {String} A formatted date string.
 */
export const toDateString = (date, month = '2-digit') =>
  date.toLocaleString('en-US', {
    month,
    year: 'numeric',
    day: '2-digit',
  });

/**
 * Formats a Date object into a string with a MM/DD/YYYY, HH:MM (AM/PM) format.
 * @example
 * const dateTimeString = toDateTimeString(new Date(2020, 0, 1, 8, 30));
 * // "01/01/2020, 8:30 AM"
 *
 * @param {Date} date A Date object to format.
 * @return {String} A formatted date time string.
 */

export const toDateTimeString = (date) => {
  date = typeof date === 'string' ? new Date(date) : date;
  const output = date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
  });
  return output;
};

/**
 * Formats an address object into a string.
 *
 * @param {object} address - an address object.
 * @param {boolean} addCountry - includes the country if true.
 * @return {string} a formatted address string.
 */
export const toAddressString = (address, addCountry) => {
  if (!address) return '';

  const streetAddress = address.unit
    ? `${address.street} Unit ${address.unit}`
    : address.street;
  const stateAndZip = [address.state, address.postalCode]
    .filter((isPresent) => isPresent)
    .join(' ');

  return [
    streetAddress,
    address.city,
    stateAndZip,
    addCountry ? 'United States' : null,
  ]
    .filter((isPresent) => isPresent)
    .join(', ');
};

/**
 * Converts an object into a select object.
 * Takes optional `label` and `value` arguments
 * to control how the object is formatted.
 *
 * @param {object} source - the object to use as an option.
 * @param {string} label - the field to use as the select label (defaults to 'name').
 * @param {string} value - the field to use as the select value (defaults to null, which uses the source as the value).
 * @return {object} the resultant select option.
 */
export const asSelectOption = (value) => ({
  value,
  label: value.name,
  active: 'active' in value ? value['active'] : true,
});

/**
 * Returns a function that can convert an object
 * into a select option.
 *
 * @param {string} label The key to use as a label for the select option (defaults to 'name')
 * @param {*} value The key to use as a value for the select option (defaults to the object)
 *
 * @example
 * const data = [{ id: 1, name: 'Thing', key: 'thing' }, ...];
 * data.map(buildAsSelectOption('name')); // Returns [{ label: 'Thing', value: { id: 1, name: 'Thing', key: 'thing' }}]
 * data.map(buildAsSelectOption('name', 'key')); // Returns [{ label: 'Thing', value 'thing' }]
 */
export const buildAsSelectOption =
  (label = 'name', value = '', active = 'active') =>
  (source) => ({
    value: value ? source[value] : source,
    label: source[label],
    active: 'active' in source ? source[active] : true,
  });

export const getInitials = (string = '') => {
  return string
    .split(' ')
    .map((word) => word[0])
    .join(' ')
    .toUpperCase();
};

export function formatDateRailsToDisplay(str) {
  if (!str) {
    return;
  }
  const dateArr = str.split('-');
  return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
}

export function formatDateDisplayToRails(str) {
  if (!str) {
    return;
  }
  const dateArr = str.split('/');
  return `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`;
}

export function dateLaterThanToday(date) {
  const today = new Date();
  const oneDayAgo = today.setDate(today.getDate() - 1);
  return new Date(date) > oneDayAgo;
}

export function decryptDigest(digest) {
  return CryptoJS.AES.decrypt(digest.toString(), digestKey).toString(
    CryptoJS.enc.Utf8
  );
}

/**
 * Format a params object for fetching inquiries, merging
 * a given params object with a given state object.
 *
 * @param {object} requestParams - an object with params for this request.
 * @param {object} state - a reducer state to infer params from.
 * @return {object} a params object for the request.
 */
export function formatParams(requestParams, state) {
  const filters = requestParams.filters || state.filters;

  const params = {
    max_id: state.infiniteLoad ? requestParams.maxId || 0 : null,
    sort: requestParams.sortParam || state.sortParam,
    status: requestParams.statusParam || state.statusParam,
    'page[number]': requestParams.pageNum || state.currentPage,
    'page[size]': requestParams.pageSize || state.pageSize,
  };

  Object.keys(params).forEach((param) => {
    if (params[param] === '') delete params[param];
  });

  const formatDate = (date) => {
    const [month, day, year] = date.split('/');
    return `${day}-${month}-${year}`;
  };

  Object.keys(filters).forEach((param) => {
    if (
      typeof filters[param] === 'object' &&
      filters[param].startDate &&
      filters[param].endDate
    ) {
      params[`filter[${param}][from]`] = formatDate(filters[param].startDate);
      params[`filter[${param}][to]`] = formatDate(filters[param].endDate);
    } else if (filters[param].length) {
      params[`filter[${param}]`] = filters[param];
    }
  });

  return params;
}

export function isPhoneNumber(str) {
  return str.match(/^\d{10}$/);
}

/**
 * Formats a string phone number into (xxx) xxx-xxxx format.
 *
 * @param {string} phoneNumber - the phone number to format.
 * @return {string} the formatted phone number.
 */
export function formatPhoneNumber(phoneNumber) {
  var m = (phoneNumber || '').match(/^(\d{3})(\d{3})(\d{4})(.*)$/);
  return !m ? phoneNumber : '(' + m[1] + ') ' + m[2] + '-' + m[3] + m[4];
}

/**
 * Formats a number into a currency string.
 *
 * @param {number} price The number to format in dollars.
 * @return {string} A formatted currency string.
 */
export function formatPrice(price, includeCents = true) {
  const asString = price.toFixed(2);

  if (includeCents) {
    return `$${parseInt(
      asString.slice(0, asString.length - 2)
    ).toLocaleString()}.${asString.slice(-2)}`;
  } else {
    return `$${parseInt(
      asString.slice(0, asString.length - 2)
    ).toLocaleString()}`;
  }
}

/**
 * Formats a number into an SSN string.
 *
 * @param {number|string} ssn The number to format as an SSN.
 * @return {string} A formatted SSN string.
 */
export function formatSsn(ssn) {
  const matches = String(ssn).match(/(\d{3})(\d{2})(\d{4})/);

  if (!matches) return ssn;

  return `${matches[1]}-${matches[2]}-${matches[3]}`;
}

/**
 * Filters through the address components from
 * a Google API response and finds an object
 * with a given type.
 *
 * @param {array} components - an array of Google API address components.
 * @param {string} type - the component type to find.
 * @return {object} the address component with the given type.
 *
 * Example:
 *
 *  axios.get('https://maps.googleapis.com/maps/api/geocode/json', {...}).then(resp => {
 *    const { address_components } = resp.data.results[0];
 *    console.log('street number', getAddressComponent(address_components, 'street_number'));
 *  });
 */
export const getAddressComponent = (components, type) =>
  components.filter(({ types }) => types.indexOf(type) !== -1)[0] || {};

/**
 * Parses an array of Google API address components
 * and returns a formatted object.
 *
 * @param {array} components - an array of Google API address components.
 * @return {object} a formatted address object.
 *
 * Example:
 *
 *  axios.get('https://maps.googleapis.com/maps/api/geocode/json', {...}).then(resp => {
 *    const { address_components } = resp.data.results[0];
 *    console.log('address', formatGoogleAddress(address_components));
 *  });
 */
export const formatGoogleAddress = (components) => ({
  street: [
    getAddressComponent(components, 'street_number').short_name,
    getAddressComponent(components, 'route').short_name,
  ].join(' '),
  unit: (
    getAddressComponent(components, 'subpremise').short_name || ''
  ).toUpperCase(),
  /**
   * NOTE: The Google Maps API will generally return an address component
   *       named `locality` or `administrative_area_level_3` that represents
   *       the city name.  This is documented in the following web page:
   *
   *       https://developers.google.com/maps/documentation/javascript/supported_types
   *
   *       In some cases, the city name is more ambiguous and neither of the
   *       above values are returned.  This seems to happen in densely populated
   *       areas, such as New York City.  In these cases, we fall back to the
   *       `sublocality` or `neighborhood` components, which seems to match the
   *       results in the official Google Maps web application.
   *
   *       For more background and discussion, see the following Google issue:
   *       https://issuetracker.google.com/issues/35820656
   */
  city:
    getAddressComponent(components, 'locality').long_name ||
    getAddressComponent(components, 'administrative_area_level_3').long_name ||
    getAddressComponent(components, 'sublocality').long_name ||
    getAddressComponent(components, 'neighborhood').long_name,
  state: getAddressComponent(components, 'administrative_area_level_1')
    .short_name,
  postalCode: getAddressComponent(components, 'postal_code').short_name,
});

/**
 * Make a GET request to Google's Geocode API to fetch a canonical
 * representation of an address.
 *
 * @param   {string|object} address  string or address object
 * @return  {Promise}                promise that resolves with a formatted address
 */
export const fetchLocationFromAddress = async (address) => {
  const response = await geolocateAddress(address);
  const result =
    response.data && response.data.results && response.data.results[0];

  if (!result) return;

  return {
    address: formatGoogleAddress(result.address_components),
    lat: result.geometry.location.lat,
    lng: result.geometry.location.lng,
  };
};

/**
 * Makes a GET request to Google's Geocode API
 * with an address object or string.
 *
 * @param {string|object} address - a string or address object.
 * @return {object} a formatted address object.
 */
export const geolocateAddress = (address) => {
  address = typeof address === 'string' ? address : toAddressString(address);

  return axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
    params: { key: GOOGLE_API_KEY, address },
  });
};

/**
 * Accepts a number of miles and returns a number of meters.
 *
 * @param {number} miles
 * @returns {number} Meters calculated from miles
 */
export const getMetersFromMiles = (miles) => miles * 1609.344;

/**
 * Returns true if a given filename has an image extension.
 *
 * @param {string} filename The filename to parse.
 * @return {boolean} True if the given filename is an image.
 */
export const isImage = (filename) =>
  /^data:image|\.(jpg|jpeg|png|gif)$/i.test(filename);

/**
 * Returns true if a given filename has a video extension.
 *
 * @param {string} filename The filename to parse.
 * @return {boolean} True if the given filename is a video.
 */
export const isVideo = (filename) =>
  /^data:video|\.(mp4|m4a|ogg)$/i.test(filename);

/**
 * Returns true if a given filename has an image or video extension.
 *
 * @param {string} filename The filename to parse.
 * @return {boolean} True if the given filename is an image or video.
 */
export const isMedia = (filename) => isImage(filename) || isVideo(filename);

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Merges existing and received conversations so that messages present in the
 * client but not in the API (unsent messages) are not overwritten by the API
 * data.
 *
 * @param   {array}  existing  array of conversation objects
 * @param   {array}  current   array of conversation objects
 * @return  {array}            array of merged old and new conversation objects
 */
export function mergeConversations(existing, current) {
  const existingConversations = existing.reduce(buildObject, {});
  const currentConversations = current.reduce(buildObject, {});

  const conversations = current.map((currentConversation) => {
    const existingConversation = existingConversations[currentConversation.id];

    if (!existingConversation) return currentConversation;

    return {
      ...currentConversation,
      messages: [
        ...currentConversation.messages,
        ...existingConversation.messages.filter(
          ({ pending, unsent }) => pending || unsent
        ),
      ],
    };
  });

  const unchangedConversations = existing.filter(
    (conversation) => !currentConversations[conversation.id]
  );
  return conversations.concat(unchangedConversations);
}

export const maxId = (max, item) =>
  parseInt(item.id) > parseInt(max) ? item.id : max;

export const minId = (min, item) =>
  parseInt(item.id) < parseInt(min) ? item.id : min;

export const buildObject = (obj, item) => {
  obj[item.id] = item;
  return obj;
};

/**
 * Remove any HTML tags and render URLs as anchor tags.
 */
export const parseHyperlinks = (message) =>
  Autolinker.link(message.replace(/<(|\/)\w+>/g, ''), {
    stripPrefix: false,
    truncate: 42,
  });

export function generateEligibilityDigest(params, digest) {
  const { firstName, lastName, employerRegistrationId } = params;
  const dateOfBirth = formatDateDisplayToRails(params.dateOfBirth);

  const salt =
    firstName.toLowerCase() +
    lastName.toLowerCase() +
    dateOfBirth +
    employerRegistrationId;
  const eligibilityDigest = sha1(salt + decryptDigest(digest));

  return eligibilityDigest.toString();
}

/**
 * Converts an array of strings into objects
 * for consumption by <ReactTags />.
 *
 * @param {string} name The name of the tag.
 * @param {integer} index The index of the tag.
 * @return {object} A tag object.
 */
export const asTag = (name, index) => ({ name, id: index });

/**
 * Converts an array of strings into objects
 * for consumption by <ReactTags />.
 *
 * @param {array} array An array of strings.
 * @return {array} An array of tag objects.
 */
export const asTags = (array) => [...array].map(asTag);

export function openNewTab(url) {
  var tab = window.open(url, '_blank');
  tab.focus();
}

export const extractValidationError = (error) => {
  const matches = error.response.data.message.match(
    /\bValidation\sfailed:\s(\D+)/
  );
  return (matches && matches[1]) || error.response.data.message;
};

export function hexToRGBA(hex, opacity) {
  const rgb = hexRGB(hex, { format: 'array' }).slice(0, 3).join(', ');

  return `rgba(${rgb}, ${opacity})`;
}

export function setDocumentTitle(
  title = '',
  baseTitle = 'Patient Care Application'
) {
  document.title =
    title && title.length > 0 ? `${title} | ${baseTitle}` : baseTitle;
}

/**
 * Returns a BMI calculation for a given height and weight.
 *
 * @param {string|number} heightFeet The height of the person (feet).
 * @param {string|number} heightInches The height of the person (inches).
 * @param {string|number} weight The weight of the person in pounds.
 * @example
 * calculateBmi('5', '11', '165'); // => "23.01"
 */
export const calculateBmi = (heightFeet, heightInches, weight) => {
  if (!heightFeet || !heightInches || !weight) return;

  const height = Number(heightFeet) * 12 + Number(heightInches);
  const heightSquared = height * height;

  return ((Number(weight) / heightSquared) * 703).toFixed(2);
};

/**
 * Checks if a string is `JSON.parse()`-able and is not just a string.
 *
 * @param {string} str The value to be checked.
 * @returns {boolean} If the value is JSON parseable but not just a string.
 */
export function isJson(str) {
  // Test if the string only contains numerical digits.
  if (/^[\d-]+$/.test(str)) return false;

  try {
    JSON.parse(str);
    str.includes('"'); // Without this check, strings will pass the `try` block.
  } catch (e) {
    return false;
  }

  return true;
}

/**
 * Convert a given distance in meters to miles.
 */
export const metersToMiles = (meters) => Math.round(meters * 0.000621371);

/**
 * Convert a given string into a defaulted integer, stripping out non-int characters.
 */
export const stringToNumber = (string) =>
  parseInt(string.replace(/[^0-9]/g, ''), 10) || 0;

/** Sort Comparisons */
export const alpha = (a, b) => a?.localeCompare(b);
export const meToFront = (a) => (a?.toLowerCase() === 'me' ? -1 : 1);
